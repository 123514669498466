<template>
    <div class="ma-0 mx-2" id="graph-full-container">

        <!-- ROUTE LOGO + VERSION -->
        <v-row>
            <!-- Route version -->
            <v-col
                cols="12"
                class="d-flex align-end mt-n2"
                :style="{ position: `relative` }"
            >
                <div 
                    class="mb-2"
                    :style="{ width: `fit-content` }"
                >
                    <v-img
                        src="route.png"
                        contain
                        width="140px"
                    />
                </div>

                <span
                    class="ml-2 pb-1 primary--text"
                    :style="{ 
                        width: `100%`,
                        fontSize: `12px`
                    }"
                >{{ currentRouteVersion }}</span>
            </v-col>
        </v-row>

        <!-- Route logo & Chart filters -->
        <v-row
            v-if="$vuetify.breakpoint.lgAndUp"
            class="ma-0 pa-0 px-0 mt-5 mb-2"
        >
            <!-- Burst filter -->
            <v-col
                xl="2"
                lg="4"
                class="ma-0 pa-0 mt-2 mr-2"
                style="min-width: 180px; max-width: 180px;"
            >
                <v-select
                    v-model="localChartView"
                    item-text="name"
                    item-value="id"
                    outlined
                    label="Filter Burst"
                    :items="burstOptions"
                    hide-details
                    dense
                    class="ma-0 pa-0"
                />
            </v-col>

            <!-- Media Owner filter -->
            <v-col 
                v-if="mediaOwnerOptions"
                xl="2"
                lg="4"
                class="ma-0 pa-0 mt-2 mr-2"
                style="min-width: 180px; max-width: 180px;"
            >
                <v-select
                    v-model="localChartMediaOwners"
                    :items="mediaOwnerOptions"
                    item-text="name"
                    item-value="id"
                    label="Filter Media Owners"
                    multiple
                    clearable
                    outlined
                    hide-details
                    dense
                    class="ma-0 pa-0"
                >
                    <template v-slot:selection="{ item, index }">
                        <span v-if="index === 0">
                            <span>{{ (hideText(item.name , 7, true)) }}</span>
                        </span>

                        <span
                            v-if="index === 1"
                            class="ml-1 grey--text text-caption"
                        >
                            (+{{ localChartMediaOwners.length - 1 }})
                        </span>
                    </template>
                </v-select>
            </v-col>

            <!-- Screen filter -->
            <v-col
                v-if="screenOptions"
                xl="2"
                lg="4"
                class="ma-0 pa-0 mt-2 mr-2"
                style="min-width: 180px; max-width: 180px;"
            >
                <v-select
                    v-if="localChartMediaOwners && localChartMediaOwners.length > 0"
                    v-model="localChartFormats"
                    :items="screenOptions"
                    item-text="name"
                    item-value="id"
                    label="Filter Formats"
                    multiple
                    chips
                    clearable
                    outlined
                    hide-details
                    dense
                    class="ma-0 pa-0"
                >
                    <template v-slot:selection="{ item, index }">
                        <span v-if="index === 0">
                            <span>{{ (hideText(item.name , 7, true)) }}</span>
                        </span>

                        <span
                            v-if="index === 1"
                            class="ml-1 grey--text text-caption"
                        >
                            (+{{ localChartFormats.length - 1 }})
                        </span>
                    </template>
                </v-select>
            </v-col>

            <!-- Plaster for responsiveness -->
            <v-spacer />
            <v-col v-if="$vuetify.breakpoint.lgAndDown" cols="2"></v-col>

            <!-- Format filter -->
            <v-col
                xl="2"
                lg="4"
                class="ma-0 pa-0 mt-2 mr-2"
                style="min-width: 180px; max-width: 180px;"
            >
                <v-select
                    v-model="chartDataBehavior"
                    :items="[{ label: 'Cumulative Data', value: 'cumulative' }, { label: 'Source Data', value: 'raw' }]"
                    item-text="label"
                    item-value="value"
                    label="Chart Format"
                    outlined
                    hide-details
                    dense
                    class="ma-0 pa-0"
                />
            </v-col>
  
            <!-- Type filter -->
            <v-col
                xl="2"
                lg="4"
                class="ma-0 pa-0 mt-2 mr-2"
                style="min-width: 180px; max-width: 180px;"
            >
                <v-select
                    v-model="localChartDataType"
                    item-text="text"
                    item-value="value"
                    outlined
                    label="Chart Type"
                    :items="[{ text: 'Verified Data', value: 'auto' }, { text: 'Tracking Data', value: 'manual' }]"
                    hide-details
                    dense
                    class="ma-0 pa-0"
                />
            </v-col>

            <!-- KPI filter -->
            <v-col
                xl="2"
                lg="4"
                class="ma-0 pa-0 mt-2"
                style="min-width: 180px; max-width: 180px;"
            >
                <v-select
                    v-model="localChartMetric"
                    return-object
                    outlined
                    label="Chart KPI"
                    :items="kpiList"
                    hide-details
                    dense
                    class="ma-0 pa-0"
                />
            </v-col>

        </v-row>

        <!-- Route Chart & Statistics -->
        <v-card id="graph-only-container"
            v-if="mapped"
            class="mt-4"
            :style="{ height: `100%`, width: `100%`, borderRadius: `0` }"
        >
        <div id="graph-thumbnail" style="width: 100%;padding: 20px;display:none">
        <div class="text-h6 font-weight-bold primary--text">
            <h1>{{ campaign.name }}</h1>
        <div class="text-h6 font-weight-bold grey--text">
            <span style="text-transform: uppercase;font-size: larger;">
                {{ 
                    localChartView !== 0 
                    ? burstOptions.find(burst => burst.id === localChartView).name
                    : 'All Bursts'
                }}
            </span>
        </div>

        <div class="text-h6 font-weight-bold grey--text">
            <span style="text-transform: uppercase; font-size: larger;">

                {{ localChartMediaOwners
                    .map(mediaOwnerId => {
                        const mediaOwner = mediaOwnerOptions.find(option => option.id === mediaOwnerId);
                        return mediaOwner ? mediaOwner.name : '';
                    })
                    .sort()
                    .join(', ') }}

            </span>
        </div>

        <div class="text-h6 font-weight-bold grey--text" style="max-width: 50%;">
            <span style="font-style: italic;">

                {{ localChartFormats
                    .map(chartFormatsId => {
                        const chartFormats = screenOptions.find(option => option.id === chartFormatsId);
                        return chartFormats ? chartFormats.name : '';
                    })
                    .sort()
                    .join(', ') }}
            </span>
        </div>
        <div>
            
        </div>
    </div>
        </div>
            <v-row
                :style="{ width: `${100}%` }"
            >
                <!-- Chart -->
                <v-row 
                    v-if="$store.state.Permissions.campaignHistoricalStats && $vuetify.breakpoint.lgAndUp" 
                    class="ma-0 pa-0 px-4"
                    :style=" { width: `${100}%` }"
                >
                    <apexchart
                        :options="chartOptions"
                        :series="sanitizedChartData"
                        height="365"
                        :style=" { width: `${100}%`, color: `#000` }"
                    />
                </v-row>
                <!-- Statistics Headers-->
                <v-row 
                    cols="12"
                    v-if="$store.state.Permissions.analytics"
                    class="d-flex justify-center ma-0 pa-0 px-4"
                    style="height: auto;"
                    :style=" { width: `${100}%` }"
                >
                    <!-- KPI -->
                    <div cols="2" class="pa-0 my-0" :style=" { width: `${20}%` }">
                        <div>
                            <v-col class="pa-0 ma-0">
                                <span
                                    class="font-weight-light"
                                    style="font-size: 13px; color: #4aa6d5"
                                >
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <span v-on="on">KPI</span>
                                        </template>
                                        <span>Key Performance Indicator</span>
                                    </v-tooltip>
                                </span>
                            </v-col>
                        </div>
                    </div>
                    <!-- Target -->
                    <div cols="2" class="pa-0 my-0" :style=" { width: `${15}%` }">
                        <div>
                            <v-col class="py-0 my-0">
                                <span
                                    class="font-weight-light"
                                    style="font-size: 13px; color: #4aa6d5"
                                >
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <span v-on="on">Target</span>
                                        </template>
                                        <span>Target Performance</span>
                                    </v-tooltip>
                                </span>
                            </v-col>
                        </div>
                    </div>
                    <!-- Actual -->
                    <div cols="2" class="pa-0 my-0" :style=" { width: `${15}%` }">
                        <div>
                            <v-col class="py-0 my-0">
                                <div class="d-flex align-center">
                                    <span
                                        class="font-weight-light"
                                        style="font-size: 13px; color: #4aa6d5"
                                    >
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <span v-on="on">Actuals</span>
                                            </template>
                                            <span>Verified Activity</span>
                                        </v-tooltip>
                                    </span>
                                </div>
                            </v-col>
                        </div>
                    </div>
                    <!-- Scope -->
                    <div v-if="$store.state.Permissions.analyticsOutOfScope && false" class="pa-0 my-0" :style=" { width: `${15}%` }">
                        <div>
                            <v-col
                                cols="auto"
                                class="py-0 my-0"
                            >
                                <div class="d-flex align-center">
                                    <span
                                        class="font-weight-light"
                                        style="font-size: 13px; color: #4aa6d5"
                                    >
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <span v-on="on">Out of Scope</span>
                                            </template>
                                            <span>Less Out of Scope Activity</span>
                                        </v-tooltip>
                                    </span>
                                    <v-icon
                                        small
                                        class="ml-1"
                                        :color="routeGraphPerformanceIcon().color"
                                    >
                                        {{ routeGraphPerformanceIcon().icon }}
                                    </v-icon>
                                    <span class="ml-1" style="font-size: 11px">{{ activeRouteGraphSummaryData['outOfScopePercentage' + chartMetric] }}</span>
                                </div>
                            </v-col>
                        </div>
                    </div>
                    <!-- Performance -->
                    <div v-if="showPerformance && $store.state.Permissions.analyticsPerformance" class="pa-0 my-0" :style=" { width: `${15}%` }">
                        <div>
                            <v-col
                                cols="auto"
                                class="py-0 my-0"
                            >
                                <div class="d-flex align-center">
                                    <span
                                        class="font-weight-light"
                                        style="font-size: 13px; color: #4aa6d5"
                                    >
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <span v-on="on">Performance</span>
                                            </template>
                                            <span>Adjusted Verified Performance</span>
                                        </v-tooltip>
                                    </span>
                                    <v-icon
                                        small
                                        class="ml-1"
                                        :color="routeGraphPerformanceIcon().color"
                                    >
                                        {{ routeGraphPerformanceIcon().icon }}
                                    </v-icon>
                                    <span class="ml-1" style="font-size: 11px">{{ currentPerformancePercent('actual') }}</span>
                                </div>
                            </v-col>
                        </div>
                    </div>
                    <!-- Forecast -->
                    <div v-if="showForecast" cols="2" class="pa-0 my-0" :style=" { width: `${15}%` }">
                        <div>
                            <v-col class="py-0 my-0">
                                <div class="d-flex align-center">
                                    <span
                                        class="font-weight-light"
                                        style="font-size: 13px; color: #4aa6d5"
                                    >
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <span v-on="on">Forecast</span>
                                            </template>
                                            <span>Projected Performance</span>
                                        </v-tooltip>
                                    </span>
                                    <v-icon
                                        small
                                        class="ml-1"
                                        :color="routeGraphPerformanceIcon('forecast').color"
                                    >
                                        {{ routeGraphPerformanceIcon('forecast').icon }}
                                    </v-icon>
                                    <span class="ml-1" style="font-size: 11px">{{ currentPerformancePercent('forecast') }}</span>
                                </div>
                            </v-col>
                        </div>
                    </div>
                    <!-- Collected -->
                    <div v-if="$store.state.Permissions.analyticsCollected" cols="2" class="pa-0 my-0" :style=" { width: `${15}%` }">
                        <div>
                            <v-col
                                cols="auto"
                                class="d-flex justify-center py-0 my-0"
                            >
                                <span
                                    class="font-weight-light"
                                    style="font-size: 13px; color: #4aa6d5" 
                                >
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <span v-on="on">Collected</span>
                                        </template>
                                        <span>% on Activity Reports</span>
                                    </v-tooltip>
                                </span>
                            </v-col>
                        </div>
                    </div>
                </v-row>
                <!-- Statistics -->
                <v-row
                    v-for="(kpi,index) in activeRouteGraphSummaryData.slice(0, 3)"
                    :key="kpi.shortName"
                    class="d-flex justify-center ma-0 pa-0 px-4 grid-container"
                    style="height: auto;"
                    cols="12"
                    :style=" { width: `${100}%` }"
                >
                    <!-- KPI -->
                    <div class="pa-0 my-0" :style=" { width: `${20}%` }">
                        <div :class="`text-h6 font-weight-bold ${(localChartMetric === kpi.name) ? 'primary--text' : 'grey--text'}`">
                            <!-- Add tooltip top to impact text-->
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <span v-on="on">{{kpi.name}}</span>
                                </template>
                                <span>{{ kpi.description }}</span>
                            </v-tooltip>
                        </div>
                    </div>

                    <!-- Target -->
                    <div class="pa-0 my-0" :style=" { width: `${15}%` }">
                        <div>
                            <v-col class="py-0 my-0">
                                <span class="text-subtitle-1">
                                    <span :class="`text-h6 font-weight-bold ${(localChartMetric === kpi.name) ? 'primary--text' : 'grey--text'}`">{{ kpi.target }}</span>
                                </span>
                            </v-col>
                        </div>
                    </div>

                    <!-- Actual -->
                    <div class="pa-0 my-0" :style=" { width: `${15}%` }">
                        <div>
                            <v-col class="py-0 my-0">
                                <span class="text-subtitle-1">
                                    <span :class="`text-h6 font-weight-bold ${(localChartMetric === kpi.name) ? 'primary--text' : 'grey--text'}`">{{ kpi.actual }}</span>
                                </span>
                            </v-col>
                        </div>
                    </div>

                    <!-- Scope -->
                    <div v-if="$store.state.Permissions.analyticsOutOfScope && false" class="pa-0 my-0" :style=" { width: `${15}%` }">
                        <div>
                            <v-col cols="auto" class="py-0 my-0">
                                <span class="text-subtitle-1">
                                    <span :class="`text-h6 font-weight-bold ${(localChartMetric === kpi.name) ? 'primary--text' : 'grey--text'}`">{{ kpi.outOfScope }}</span>
                                </span>
                            </v-col>
                        </div>
                    </div>

                    <!-- Performance -->
                    <div v-if="showPerformance && $store.state.Permissions.analyticsPerformance" class="pa-0 my-0" :style=" { width: `${15}%` }">
                        <div>
                            <v-col
                                cols="auto"
                                class="py-0 my-0"
                            >
                                <span class="text-subtitle-1">
                                    <span :class="`text-h6 font-weight-bold ${(localChartMetric === kpi.name) ? 'primary--text' : 'grey--text'}`">{{ kpi.performance }}</span>
                                </span>
                            </v-col>
                        </div>
                    </div>

                    <!-- Forecast -->
                    <div v-if="showForecast" cols="2" class="pa-0 my-0" :style=" { width: `${15}%` }">
                        <div>
                            <v-col
                                cols="auto"
                                class="py-0 my-0"
                            >
                                <span class="text-subtitle-1">
                                    <span :class="`text-h6 font-weight-bold ${(localChartMetric === kpi.name) ? 'primary--text' : 'grey--text'}`">{{ kpi.forecast }}</span>
                                </span>
                            </v-col>
                        </div>
                    </div>

                    <!-- Collected -->
                    <div v-if="$store.state.Permissions.analyticsCollected && index == 1" cols="2" class="pa-0 my-0 spanRows" :style=" { width: `${15}%` }">
                        <v-progress-circular

                            v-if="campaignVerifications"
                            @click="showVerifyRouteCollected = true"
                            :rotate="360"
                            :size="56"
                            :width="5"
                            :value="activeRouteGraphVerificationsPercentage"
                            :color="activeRouteGraphVerificationsColor"
                            :class="($vuetify.breakpoint.xl) ? 'mouse-pointer ma-0 pa-0' : 'mouse-pointer ma-0 pa-0 mt-n5'"
                            :style="($vuetify.breakpoint.xl) ? 'font-size: 16px' : 'font-size: 12px'"
                        >{{ `${activeRouteGraphVerificationsPercentage}%` }}</v-progress-circular>
                    </div>
                    <div v-else cols="2" class="pa-0 my-0" :style=" { width: `${15}%` }"></div>
              </v-row>
            </v-row>
            <!-- Verify Route Collected Dialog -->
            <v-dialog
                v-model="showVerifyRouteCollected"
                width="800"
            >
                <verify-route-collected
                    v-if="showVerifyRouteCollected"
                    :campaignId="campaign.id"
                    :campaignVerifications="campaignVerifications"
                />
            </v-dialog>
        </v-card>
    </div>
</template>

<script>
    // Components
    // lazy load
    const VerifyRouteCollected = () => import('@/components/verify/route/Collected')
    
    // Services
    import RouteController from '@/services/controllers/Route'

    // Mixins
    import helpersColor from '@/mixins/helpers/color'
    import roundNumber from '@/mixins/helpers/roundNumber'
    import helpersConcatText from '@/mixins/helpers/concatText'

    // External libraries
    import moment from 'moment'
    import apexchart from 'vue-apexcharts'
    import cumulativeSum from "cumulative-sum"

    import html2canvas from 'html2canvas';
    import cloneDeep from 'lodash/cloneDeep';
    
    export default {
        mixins: [
            helpersColor,
            roundNumber,
            helpersConcatText
        ],

        props: {
            campaign: {
                required: true
            },

            chartMetric: {
                type: String,
                required: true,
            },

            chartDataType: {
                type: String,
                required: true,
            },

            selectedBurst: {
                required: true,
                default: 0
            },

            chartMediaOwners: {
                required: true,
            },

            chartScreens: {
                required: true,
            },
            kpiList: {
                type: Array,
                required: true
            },
            kpiListObjects: {
                type: Object,
                required: true
            },
            data: {
                type: Object,
                required: true
            },
            arbSync: {
                type: Number,
                default: 0,
                required: true
            },
            type: {
                type: String,
                default: 'report'
            },
            routeRequest: {
                type: Object,
                required: true
            }
        },

        components: {
            VerifyRouteCollected,
            apexchart,
        },

        data() {
            return {
                chartDataTransformed: {},
                chartDataTransformedOriginal: {},
                chartDisplayData: [],
                showForecast: true,
                showPerformance: true,

                calculatedRouteVersion: '',
                
                chartDataBehavior: 'cumulative',

                showVerifyRouteCollected: false,

                campaignVerifications: null,
                
                mapped: false,
                activeRouteGraphSummaryData: [],

                // set designations
                designations: ['target','actual','forecast'],
                graphWorker: null,
            }
        },

        watch: {
            localChartView: function (newVal, oldVal) {
                let results = []

                if(this.chartMediaOwners && this.chartMediaOwners.length > 0) {
                    this.mediaOwnerOptions.forEach(mediaOwnerOption => {
                        this.chartMediaOwners.forEach(selectedMediaOwnerId => {
                            if(mediaOwnerOption.id == selectedMediaOwnerId)
                                results.push(selectedMediaOwnerId)
                        })
                    })

                    const uniqueResults = [...new Set(results)].sort()
                    const compareResults = this.chartMediaOwners.sort()

                    if(JSON.stringify(compareResults) !== JSON.stringify(uniqueResults)) {
                        this.$emit('updateChartMediaOwners', uniqueResults)
                    }
                }
                this.$emit('updateSelectedFilterBurst', newVal)

                this.mapStats()
            },
            
            localChartFormats: function (newVal, oldVal) {
                this.mapStats()
            },

            localChartMediaOwners: function (newVal, oldVal) {
                let results = []

                if(this.chartScreens && this.chartScreens.length > 0) {
                    this.screenOptions.forEach(screenOption => {
                        this.chartScreens.forEach(selectedScreenId => {
                            if(screenOption.id == selectedScreenId)
                                results.push(selectedScreenId)
                        })
                    })

                    const uniqueResults = [...new Set(results)].sort()
                    const compareResults = this.chartScreens.sort()

                    if(JSON.stringify(compareResults) !== JSON.stringify(uniqueResults)) {
                        this.$emit('updateChartScreens', uniqueResults)
                    }
                }
                
                this.mapStats()
            },

            localChartDataType: function (newVal, oldVal) {
                if(newVal != oldVal) {
                    this.mapStats()
                }
            },

            arbSync: async function(val, oldVal) {
                {
                    let tab = this.$store.getters.getCampaignTab
                    //if(val != oldVal && tab == this.type)
                    {
                        this.mapped = false
                        this.mapStats()
                        await this.getAllData()
                    }
                }
            },
        },

        computed: {
            // PURPOSE: Selected chart KPI
            localChartMetric: {
                get() {
                    return this.chartMetric
                },

                set(v) {
                    this.$emit('updateChartMetric', v);
                }
            },

            // PURPOSE: Selected chart type
            localChartDataType: {
                get() {
                    return this.chartDataType
                },

                set(v) {
                    this.$emit('updateChartDataType', v);
                }
            },

            // PURPOSE: Selected chart burst & default to 0 if all selected
            localChartView: {
                get() {
                    // if there's only 1 burst available, return that
                    if(this.burstOptions.length === 1) {
                        return this.burstOptions[0].id
                    }
                    return this.selectedBurst;
                },

                set(v) {
                    this.$emit('updateSelectedFilterBurst', v);
                }
            },

            // PURPOSE: Selected chart media owners
            localChartMediaOwners: {
                get() {
                    return this.chartMediaOwners
                },

                set(v) {
                    this.$emit('updateChartMediaOwners', v);
                }
            },

            // PURPOSE: Selected chart screens/formats
            localChartFormats: {
                get() {
                    return this.chartScreens
                },

                set(v) {
                    this.$emit('updateChartScreens', v);
                }
            },
            // PURPOSE: remove all option from bursts if they have a clash in dates
            burstOptions() {
                let bursts = [...this.campaign.campaignBursts]

                if(bursts.length > 1) {
                    bursts.unshift({id: 0, name: 'All'})
                }

                if(bursts.length === 1) {
                    this.localChartView = bursts[0].id
                }

                return bursts
            },

            // PURPOSE: Show available media owners
            mediaOwnerOptions() {
                if(this.chartDataTransformedOriginal.bursts) {
                    let mediaOwners = []
                    // filter by auto/manual
                    const chartByDataType = cloneDeep([this.chartDataTransformedOriginal]) // campaign level

                    if(this.localChartView !== 0) {

                        const selectedBurst = chartByDataType[0].bursts.find(burst => burst.id === this.localChartView)

                        selectedBurst.mediaOwners.forEach(mediaOwner => {
                            mediaOwners.push({ id: mediaOwner.id, name: mediaOwner.name })
                        })
                    }
                    else {
                        chartByDataType[0].bursts.forEach(burst => {
                            burst.mediaOwners.forEach(mediaOwner => {
                                if(!mediaOwners.includes({ id: mediaOwner.id, name: mediaOwner.name })) {
                                    mediaOwners.push({ id: mediaOwner.id, name: mediaOwner.name })
                                }
                            })
                        })
                    }
                    
                    return mediaOwners
                    }

                return []
            },

            // PURPOSE: Show available screens
            screenOptions() {
                if(this.chartDataTransformedOriginal.bursts) {
                    let screens = []

                    // filter by auto/manual
                    const chartByDataType = [this.chartDataTransformedOriginal]

                    // Get selected burst(s)
                    const selectedBursts = (this.localChartView !== 0) ? [chartByDataType[0].bursts.find(burst => burst.id === this.localChartView)] : chartByDataType[0].bursts

                    // Loop through bursts
                    selectedBursts.forEach(burst => {
                        // Loop through media owners
                        burst.mediaOwners.forEach(mediaOwner => {
                            // Loop through screens
                            mediaOwner.screens.forEach(screen => {
                                // Filter by selected media owners
                                if(this.localChartMediaOwners && this.localChartMediaOwners.length > 0) {
                                    this.localChartMediaOwners.forEach(id => {
                                        if(!screens.includes({ id: screen.id, name: screen.name }) && (id === mediaOwner.id)) {
                                            screens.push({ id: screen.id, name: screen.name })
                                        }
                                    })
                                }
                                // Filter by all
                                else 
                                {
                                    screens.push({ id: screen.id, name: screen.name })
                                }
                            })
                        })
                    })
                    return screens
                }

                return []
            },

            // PURPOSE: Show current route version
            currentRouteVersion() {
                let currentRouteVersion = []

                if(this.chartDataTransformedOriginal.bursts) {

                    // filter by auto/manual
                    const chartByDataType = ([this.chartDataTransformedOriginal])

                    // Filter by bursts needed (get an array)
                    let chartByBurst = this.splitChartByBurst(chartByDataType)

                    chartByBurst.forEach(burst => {
                        if(burst.data.routeVersions.length > 0)
                            currentRouteVersion.push(burst.data.routeVersions.toString())
                    })
                }

                const unique = currentRouteVersion.filter((x, i, a) => a.indexOf(x) == i)

                return (unique.length > 0) ? unique.join(',') : ''
            },

            // PURPOSE: Chart styling options
            chartOptions() {
                let options = {
                    plotOptions: {
                        bar: {
                            columnWidth: '50%' // Set the width of bars to 50% of available space
                        }
                    },
                    chart: {
                        foreColor: (this.$vuetify.theme.dark) ? '#fff' : '#000',
                        height: 350,
                        toolbar: {
                            show: true,
                            tools: {
                                download: false,
                                export: false,
                                selection: true,
                                zoom: true,
                                zoomin: true,
                                zoomout: true,
                                pan: true,
                                reset: true,
                                customIcons: [{
                                    icon: '<div class="mdi mdi-content-copy" style="font-size: 17px; margin-left: 3px; margin-right: -8px;"></div>',
                                    index: 4,
                                    title: 'Copy Graph to Clipboard',
                                    click: () => { this.saveGraphScreenshot(true) }
                                },
                                {
                                    icon: '<div class="mdi mdi-file-image" style="font-size: 17px; margin-left: 3px; margin-right: -8px;"></div>',
                                    index: 5,
                                    title: 'Export Graph to PNG',
                                    click: () => { this.saveGraphScreenshot(false) }
                                },
                                {
                                    icon: '<div class="mdi mdi-file-table" style="font-size: 17px; margin-left: 3px; margin-right: -8px;"></div>',
                                    index: 6,
                                    title: 'Export Graph to CSV',
                                    click: () => { this.exportToCSV() }
                                }]
                            },
                            export: {
                    csv: {
                        //
                        filename: this.campaign.name + '-chart-export-',
                        columnDelimiter: ',',
                        headerCategory: 'Date',
                        headerValue: 'value'
                    },
                    svg: false,
                    png: false,
                    },
                            autoSelected: 'zoom'
                        },
                    },

                    stroke: {
                        width: 3,
                    },

                    xaxis: {
                        tickAmount: this.xTickCalculation,
                        tickPlacement: 'between',
                        type: 'datetime',
                        labels: {
                            rotate: -45,
                            rotateAlways: true,
                            minHeight: 45,
                            formatter: function (val, timestamp, index) {
                                return moment(new Date(timestamp)).format("DD MMM");
                            },
                        },
                    },
                    grid: {
                        padding: {
                            left: 20,
                            right: 6,
                        },
                    },
                    yaxis: {
                        labels: {
                            formatter: (value) => {
                                return value.toFixed(0)
                            }
                        }
                    },

                    tooltip: {
                        shared: false,
                        x: {
                            formatter: (x) => {
                                if (typeof x !== "undefined") {
                                    return moment(new Date(x)).format("DD MMM HH:mm:ss")
                                }
                                return x;
                            }
                        },
                        y: {
                            formatter: (y) => {
                                if (typeof y !== "undefined")
                                    return ((y) ? y.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'No ') + " " + this.chartMetric
                                return y.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            }
                        }
                    },

                    markers: {
                        size: 4,
                    },

                    legend: {
                        show: false,
                        position: "bottom",
                        offsetX: 0,
                        offsetY: 0,
                    }
                }

                return options;
            },
            // PURPOSE: Show a date for every point on the chart
            xTickCalculation() {
                let sData = this.sanitizedChartData
                if(sData.filter(data => data.dataType === 'target') !== undefined) {
                    const targets = sData.filter(data => data.dataType === 'target')
                    if(targets.length === 1) {
                        return targets[0].data.length - 1 
                    }
                }
                return null
            },

            // PURPOSE: Convert data to chart format
            sanitizedChartData() {
                if(!this.data.bursts) return []
                
                //let chartDisplayData = cloneDeep(this.transformChartData()) // JSON.parse(JSON.stringify(this.transformChartData())) // if we don't clone, it changes the original object
                //let chartDisplayData = this.transformChartData() // if we don't clone, it changes the original object

                // Data gets transformed for chart structure below

                // Filter by metric selected
                const chartByMetric = this.splitChartByMetric(cloneDeep(this.chartDisplayData))

                // Remove overlapping chart data
                //const splitChartData = this.removeOverlappingChartData(chartByMetric)

                // Map data by format type
                const chartByFormatType = this.mapChartByFormatType(chartByMetric)

                // Remove redundant forecast data
                //const chartSanitized = this.removeChartForecast(chartByFormatType)
                const chartSanitized = chartByFormatType

                // Split chart data into individual objects in the array
                const chartData = this.splitChartData(chartSanitized)  

                // Delete item from an object in the chartData if its .x or .y proerty is === 0
                /*
                chartData.forEach((item, index) => {
                    if(item.dataType === 'target') {
                        item.data.forEach((item, index) => {
                            if(item.y === 0 || item.x === 0){
                                // Delete item from chartData array
                                chartData.splice(index, 1)
                            }
                        })
                    }
                })
                */
                // make chartData realiant on arbSync
                chartData.arbSync = this.arbSync
                
                return chartData
            },

            addCommas() {
                return (number) => {
                const num = parseFloat(number).toFixed(2);
                // Convert the number to a string
                const numberString = num.toString();
                // Split the number into integer and decimal parts
                const [integerPart, decimalPart] = numberString.split('.');
                // Add commas to the integer part
                const numberWithCommas = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

                // Combine the integer part with the decimal part (if any)
                const result = decimalPart !== undefined ? numberWithCommas + '.' + decimalPart : numberWithCommas;
                // Return the result
                
                return result;
                }
            },

            // PURPOSE: Show collected % based off current selection
            activeRouteGraphVerificationsPercentage() {
                // Result (return at bottom)
                let percentage = 0

                // Filter the screen data
                if(this.localChartFormats && this.localChartFormats.length > 0) { 
                    let amountAdded = 0
                    
                    this.campaignVerifications.bursts.forEach(burst => {
                        burst.mediaOwners.forEach(mediaOwner => {
                            this.localChartFormats.forEach(id => {
                                let screen = mediaOwner.screens.find(screen => screen.screenId === id)
                                
                                if(screen && (this.localChartView == 0 || this.localChartView == burst.campaignBurstId)) {
                                    percentage += Number(screen.percentage)
                                    amountAdded += 1
                                }
                            })
                        })
                    })

                    percentage = (amountAdded > 0) ? this.roundMaxDecimals(percentage / amountAdded, 1) : 0
                }

                // Filter the media owner data
                else if(this.localChartMediaOwners && this.localChartMediaOwners.length > 0) { 
                    let amountAdded = 0
                    
                    this.campaignVerifications.bursts.forEach(burst => {
                        this.localChartMediaOwners.forEach(id => {
                            let mediaOwner = burst.mediaOwners.find(mediaOwner => mediaOwner.mediaOwnerId === id)
                            
                            if(mediaOwner && (this.localChartView == 0 || this.localChartView == burst.campaignBurstId)) {
                                percentage += Number(mediaOwner.percentage)
                                amountAdded += 1
                            }
                        })
                    })

                    percentage = (amountAdded > 0) ? this.roundMaxDecimals(percentage / amountAdded, 1) : 0
                }

                // Filter the bursts data
                else if(this.localChartView == 0) {
                    percentage = this.roundMaxDecimals(this.campaignVerifications.percentage, 1)
                }

                // Filter burst data
                else if(this.localChartView != 0) {
                    percentage = this.roundMaxDecimals(this.campaignVerifications.bursts.find(burst => burst.campaignBurstId == this.localChartView).percentage, 1)
                }

                // Return the result
                return percentage
            },

            // PURPOSE: Get a color based off collected %
            activeRouteGraphVerificationsColor() {
                if(this.activeRouteGraphVerificationsPercentage >= 80) {
                    return 'green'
                }
                else if(this.activeRouteGraphVerificationsPercentage >= 50) {
                    return 'amber'
                }
                
                return 'red'
            }
        },

        async created() {

        },
        beforeDestroy() {
            if(this.graphWorker)
                this.graphWorker.terminate();
            // garbage collection
            console.log("Graph destroyed")
            this.chartDataTransformed = null
            this.campaignVerifications = null
            this.activeRouteGraphSummaryData = []
        },
        async mounted() {
            const thisInstance = this
            //this.$root.$on('reloadRouteGraph', () =>{ thisInstance.getAllData() })
            let tab = this.$store.getters.getCampaignTab || this.$route.query.tab
            if(tab != this.type) return
            if(!this.mapped)
                await this.mapStats()
            await this.getAllData()
        },
        methods: {
            mapStats() {
                //if(this.mapped) return
                if(!this.graphWorker)
                {
                    this.graphWorker = new Worker('/scripts/workers/GraphWorker.js?v1.0.1');
                    this.graphWorker.onmessage = (event) => {
                        this.chartDataTransformed = event.data // transform to proper format
                        if(!this.mapped)
                            this.chartDataTransformedOriginal = cloneDeep(this.chartDataTransformed)

                        this.transformChartData()
                        
                        // Component is finished mounting and can be shown on screen now
                        this.mapped = true;
                    };
                }

                if(!this.data.bursts) return
                let data = {
                    chartMetric: this.chartMetric,
                    chartData: this.data,
                    kpiListObjects: this.kpiListObjects,
                    designations: this.designations,
                    chartDataType: this.chartDataType,
                    localChartMediaOwners: this.localChartMediaOwners,
                    localChartFormats: this.localChartFormats,
                    campaign: this.campaign,
                    localChartView: this.localChartView
                }
                this.graphWorker.postMessage(data)
            },

            getKpiIdFromName(kpiName) {
                let o = this.kpiListObjects.KPIs.find(kpi => kpi.name === kpiName)
                if(o) return o.id
            },

            getKpiIdFromShortName(shortName) {
                let o = this.kpiListObjects.KPIs.find(kpi => kpi.shortName === shortName)
                if(o) return o.id
            },

            getKpiShortNameFromName(kpiName) {
                let o = this.kpiListObjects.KPIs.find(kpi => kpi.name === kpiName)
                if(o) return o.shortName
            },

            getDesignationIdFromShortName(shortName) {
                let o = this.kpiListObjects.DESIGNATIONs.find(designation => designation.shortName === shortName)
                if(o) return o.id
            },

            getModeIdFromShortName(shortName) {
                let o = this.kpiListObjects.MODEs.find(mode => mode.shortName === shortName)
                if(o) return o.id
            },

            getKpiShortNameFromId(id) {
                let o = this.kpiListObjects.KPIs.find(kpi => kpi.id === id)
                if(o) return o.shortName
            },
            async saveGraphScreenshot(isClipboard) {
                // Get the element to be captured
                const graphElement = document.getElementById('graph-only-container');
                // Check if the element is found
                if (!graphElement) {
                    this.$root.$emit('snackbarError', 'Error capturing screenshot: Graph element not found, try reloading the page.');
                    return;
                }
                try {
                    // Use html2canvas to capture the content of the element
                    const canvas = await html2canvas(graphElement, {
                    scale: 6,
                    dataURL: true,
                    logging: false,
                    onclone: function(document) {
                        let graphHoverElements = document.querySelectorAll('.apexcharts-active');
                        graphHoverElements.forEach(function(element) {
                        // Remove 'theme--dark' class
                        element.classList.remove('apexcharts-active');
                        });
                        let graphThumbnail = document.querySelector('#graph-thumbnail');
                        graphThumbnail.style.display = 'block';

                        // Get all elements with the class 'theme--dark'
                        let darkElements = document.querySelectorAll('.theme--dark');
                        // Loop through each element and switch the classes
                        darkElements.forEach(function(element) {
                            // Remove 'theme--dark' class
                            element.classList.remove('theme--dark');
                            // Add 'theme--light' class
                            element.classList.add('theme--light');
                        });

                        // REMOVE GRAPH TOOLBAR
                        let graphToolbar = document.querySelector('.apexcharts-toolbar');
                        if (graphToolbar) {
                            graphToolbar.style.display = 'none';
                        }

                        // Get all <text> elements on the page
                        let textElements = document.querySelectorAll('text');

                        // Loop through each <text> element and change the fill attribute
                        textElements.forEach(function(element) {
                        // Change the fill attribute to your desired value (e.g., 'red')
                        element.setAttribute('fill', '#6e6e6e');
                        });

                        // Get all mdi-trending-neutral elements on the page
                        let textElem = document.querySelectorAll('.mdi');
                        textElem.forEach(function(element) {

                        element.classList.add('mdi-dark');
                        // make fill black important css
                        element.style.color = 'black!important';
                        element.style.caretColor = 'black!important';
                        });
                    }
                    });

                    const dataUrl = canvas.toDataURL('image/png');
                    if (isClipboard) {
                    // If isClipboard is true, copy the image data to the clipboard
                    const blob = await (await fetch(dataUrl)).blob();

                    await navigator.clipboard.write([
                        new ClipboardItem({ 'image/png': blob })
                    ]);
                    this.$root.$emit('snackbarSuccess', 'Graph copied to clipboard.')
                    } else {
                    // Create a link element to trigger the download
                    const link = document.createElement('a');
                    link.href = dataUrl;
                    // Datetime unique file name for each screenshot
                    link.download = this.campaign.name + '-export-img-' + new Date().toISOString() + '.png';
                    // Append the link to the document and trigger the download
                    document.body.appendChild(link);
                    link.click();
                    // Remove the link from the document
                    document.body.removeChild(link);
                    this.$root.$emit('snackbarSuccess', 'Graph exported to PNG.')
                    }
                } catch (error) {
                    this.$root.$emit('snackbarError', 'Error capturing screenshot: ' + error);
                }
            },
            exportToCSV() {
                try{
                    // Extract data from the chart instance

                    const chartData = this.sanitizedChartData;
                    // Filter out data with the name containing "dead space"
                    const filteredChartData = chartData.filter(item => {
                            return !item.name.includes('dead space')
                        });

                    // Create a CSV content with headers
                    let csvContent = this.campaign.name + " (" + this.localChartMetric + ") - Verified\n\n";

                    try{
                        // BURSTS
                        csvContent += 'Burst';
                        if (this.localChartView) {
                            csvContent += ':," ' + this.burstOptions.find(burst => burst.id === this.localChartView).name;
                        }
                        else {
                            csvContent += 's (All):," ' + this.burstOptions.map(burst => burst.name).sort().slice(1).join(', ');
                        }
                        csvContent += '"'; // Closing quote 
                        csvContent += "\n"; // New line
                    }
                    catch(error){
                        console.log(error)
                    }
                    // END BURSTS

                    // MEDIA OWNERS
                    try{
                        csvContent += 'Media Owner';
                        if (this.localChartMediaOwners.length > 1) {
                            csvContent += 's:," ' + this.localChartMediaOwners
                                .map(mediaOwnerId => {
                                    const mediaOwner = this.mediaOwnerOptions.find(option => option.id === mediaOwnerId);
                                    return mediaOwner ? mediaOwner.name : '';
                                })
                                .sort()
                                .join(", ");
                        } 
                        else if (this.localChartMediaOwners.length === 1) {
                            csvContent += ':," ' + this.mediaOwnerOptions.find(option => option.id === this.localChartMediaOwners[0]).name;
                        }
                        else {
                            csvContent += 's (All):," ' + this.mediaOwnerOptions.map(mediaOwner => mediaOwner.name)
                            .sort()
                            .join(', ');
                        }
                        csvContent += '"'; // Closing quote 
                        csvContent += "\n"; // New line
                    }
                    catch(error){
                        console.log(error)
                    }
                    // END MEDIA OWNERS

                    // FORMATS
                    try{
                        csvContent += 'Format';

                        if (this.localChartFormats.length > 1) {
                            csvContent += 's:," ' + this.localChartFormats
                                .map(chartFormatsId => {
                                    const chartFormats = this.screenOptions.find(option => option.id === chartFormatsId);
                                    return chartFormats ? chartFormats.name : '';
                                })
                        .sort()
                        .join(', ');
                        }
                        else if (this.localChartFormats.length === 1) {
                        csvContent += ':," ' + this.screenOptions.find(option => option.id === this.localChartFormats[0]).name;
                        }
                        else {
                        csvContent += 's (All):," ' + this.screenOptions.map(option => option.name)
                        .sort()
                        .join(', ');
                        }
                        csvContent += '"'; // Closing quote 
                        csvContent += "\n"; // New line
                    }
                    catch(error){
                        console.log(error)
                    }
                    // END FORMATS

                    // Add date heading
                    csvContent += "\nDate,";

                    // Extract unique names from the filtered chart data
                    const uniqueNames = Array.from(
                        new Set(filteredChartData.map(item => item.name))
                    );

                    // Remove this.campaign.name string from the start of each unique names by the length
                    uniqueNames.forEach(name => {
                        csvContent += getCSVHeadingSuffix(name) + ",";
                    });
                    csvContent += "\n";

                    // Sort chart data items by date ascending
                    filteredChartData.forEach(item => {
                        item.data.sort((a, b) => new Date(a.x) - new Date(b.x));
                    });

                    // Create an object with data grouped by date
                    const dataByDate = {};
                    filteredChartData.forEach(item => {
                        item.data.forEach(point => {
                            const date = timestamp(new Date(point.x));
                            if (!dataByDate[date]) {
                                dataByDate[date] = {};
                            }
                            dataByDate[date][item.name] = point.y;
                        });
                    });

                    // Add rows to CSV
                    Object.keys(dataByDate).forEach(date => {
                        csvContent += date;
                        uniqueNames.forEach(name => {
                            csvContent += `,${Math.round(dataByDate[date][name] || 0)}`;
                        });
                        csvContent += "\n";
                    });

                    csvContent += "\n[Exported " + timestamp(null, true) + "]";

                    // Create a data URL for the CSV
                    const csvData = encodeURI(csvContent);
                    const link = document.createElement("a");
                    link.setAttribute("href", "data:text/csv;charset=utf-8," + csvData);
                    link.setAttribute("download", this.campaign.name + '-export-csv-' + new Date().toISOString() + ".csv");
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    this.$root.$emit('snackbarSuccess', 'Graph exported to CSV.');
                } catch (error) {
                    this.$root.$emit('snackbarError', 'Error exporting to CSV: ' + error);
                }

                function timestamp(date, addTime = false) {
                    if (!date) {
                        date = new Date();
                    }
                    let returnDate = "";
                    returnDate = date.toLocaleDateString("en-GB", { day: '2-digit', month: '2-digit', year: 'numeric' });
                    if (addTime) {
                        returnDate += " " + date.toLocaleTimeString("en-GB", { hour: '2-digit', minute: '2-digit' });
                    }
                    return returnDate;
                }

                function getCSVHeadingSuffix(inputHeading) {
                    // Find the last occurrence of a hyphen
                    const lastHyphenIndex = inputHeading.lastIndexOf('-');

                    let result = null;
                    
                    // Check if a hyphen was found
                    if (lastHyphenIndex !== -1) {
                        // Extract the substring after the last hyphen
                        result = inputHeading.substring(lastHyphenIndex + 1).trim();
                        
                    } else {
                        // If no hyphen is found, return the original heading
                        result = inputHeading.trim();
                    }

                    return result;
                }
            },

            // PURPOSE: Call all end points
            async getAllData() {
                await this.getDashboardVerifications()
            },

            // PURPOSE: Get route chart/line collected (%) data
            async getDashboardVerifications() {
                if(!this.campaign.id || !this.routeRequest.id) return
                const { data = null } = await RouteController.getDashboardVerifications(this.campaign.id, this.routeRequest.id).catch(err => {
                    this.$root.$emit('snackbarError',''+ err.response.data.message)
                })

                this.campaignVerifications = data
            },

            // PURPOSE: Split chart data by campaign
            splitChartByCampaign(campaigns) {
                campaigns.forEach(campaign => 
                {
                    // clean out zeros
                    //campaign = this.cleanChartData(campaign);

                    return campaign
                })
                return campaigns
            },

            // PURPOSE: Split chart by selected burst
            splitChartByBurst(data) {
                // Get specific burst
                if(this.localChartView !== 0) {
                    return [data[0].bursts.find(burst => burst.id === this.localChartView)]
                }

                // Get all bursts
                return data[0].bursts
            },

            cleanChartData(chartData) {
                this.designations.forEach(key => {
                    chartData.data[key] = chartData.data[key].filter(x => !(x.plays == 0 && x.hours == 0 && x.impacts == 0) || key == 'forecast')
                })
                return chartData;
            },

            // PURPOSE: SPlit nested data into array of actual, forecasts and targets
            splitChartData(incomingChartData) {
                let results = [];

                const lineName = (dataType) => {
                    if (dataType === 'target')
                        return 'Target';
                    if (dataType === 'forecast')
                        return 'Forecast';
                    if (dataType === 'actual')
                        return 'Actual';

                    return 'N/A';
                };

                const mapArray = (campaign, dataType, type) => {
                    // check if array has data (else skip)
                    let dataCollection = [];

                    const addNormalSpace = (campaign, dc) => {
                        results.push({
                            dataType: dataType,
                            name: `${campaign.name} - ${lineName(dataType)}`,
                            data: dc,
                            type: type,
                            curve: "straight",
                            color: this.calculateChartColor(dataType),
                            dash: 0
                        });
                    };

                    const addDeadSpace = (campaign, dc) => {
                        if (this.chartDataBehavior === 'cumulative') {
                            results.unshift({
                                dataType: dataType,
                                name: `${campaign.name} - dead space`,
                                data: dc,
                                type: type,
                                curve: "straight",
                                color: "#504f4f",
                                dash: 0,
                            });
                        }
                    };

                    let deadSpace = false;
                    let deadStart = null;
                    // make sure dates are ordered
                    campaign.data[dataType].sort((a, b) => moment(a.x) - moment(b.x));
                    let cdata = campaign.data[dataType];
                    
                    for (let i = 0; i < cdata.length; i++) {
                        let celem1 = cdata[i];
                        let d1 = moment(celem1.x);
                        if (deadSpace) {
                            deadSpace = false;
                            let deadEnd = celem1;

                            // Fix: Create a separate object for deadStart point
                            deadStart = {
                                x: celem1.x,
                                y: celem1.y,
                            };

                            // Fix: Set the y value of deadStart to itself
                            deadStart.y = deadStart.y;

                            //addDeadSpace(campaign, [deadStart, deadEnd]);
                            deadStart = null;
                        }
                        dataCollection.push(celem1);
                        let celem2 = cdata[i + 1];
                        if (celem2) {
                            let d2 = moment(celem2.x);
                            let daysDiff = d2.diff(d1, 'days');
                            if (daysDiff != 1) {
                                deadStart = celem1;
                                deadSpace = true;
                                addNormalSpace(campaign, dataCollection);
                                dataCollection = [];
                            }
                        }
                    }
                    if (dataCollection.length > 0) {
                        addNormalSpace(campaign, dataCollection);
                        dataCollection = [];
                        
                    }
                    deadSpace = true;
                    
                };
                mapArray(incomingChartData, 'target', 'line');
                mapArray(incomingChartData, 'forecast', 'line');
                mapArray(incomingChartData, 'actual', 'bar');

                return results;
            },


            //
            removeOverlappingChartData(bursts) {
                let results = []

                if(false && bursts && bursts.length > 1) {
                    for (let index = 1; index < bursts.length; index++) {
                        const previousBurst = bursts[index - 1];
                        const currentBurst = bursts[index];

                        previousBurst.data.target.forEach(previousTarget => {
                            currentBurst.data.target.forEach(currentTarget => {
                                if(previousTarget.x == currentTarget.x) {
                                }
                            })
                        })
                    }
                }

                return bursts
            },

            // PURPOSE: Split chart by selected KPI
            splitChartByMetric(chartData) {

                //bursts.forEach(burst => 
                {
                    let kpiShortname = this.getKpiShortNameFromName(this.localChartMetric)
                    chartData.data.actual = chartData.data.actual.map(data => { return { x: data.date, y: data[kpiShortname] } })
                    chartData.data.forecast = chartData.data.forecast.map(data => { return { x: data.date, y: data[kpiShortname] } })
                    chartData.data.target = chartData.data.target.map(data => { return { x: data.date, y: data[kpiShortname] } })
                }
                //)

                return chartData
            },

            // PURPOSE: Split chart by selected metric & add dead space if needed
            mapChartByFormatType(burst) {
                if(this.chartDataBehavior === 'cumulative') {

                    // Take the y axis out into its own array to pass into the cumulative sum function
                    let actual = burst.data.actual.map(data => { return data.y })
                    let forecast = burst.data.forecast.map(data => { return data.y })
                    let target = burst.data.target.map(data => { return data.y })

                    // Run a cumulative sum
                    actual = cumulativeSum(actual)
                    forecast = cumulativeSum(forecast)
                    target = cumulativeSum(target)

                    // Put new y axis values in place of the old ones
                    burst.data.actual.forEach((data, index) => { data.y = actual[index] })
                    burst.data.forecast.forEach((data, index) => { data.y = forecast[index] })
                    burst.data.target.forEach((data, index) => { data.y = target[index] })
                }

                return burst
            },

            // PURPOSE: Remove chart's redundant forecast data
            removeChartForecast(burst) {
                this.showForecast = false
                this.showPerformance = false

                let spliceUntilIndex = null, maxDate = null

                // Get max date the actual data is showing to
                burst.data.actual.forEach(actual => { maxDate = actual.x })

                burst.data.forecast.forEach((forecast, index) => { if(forecast.x === maxDate){ spliceUntilIndex = index } })

                burst.data.forecast.splice(0, spliceUntilIndex)

                // If there is only one item in the forecast data then delete it
                if(burst.data.forecast.length === 1) { burst.data.forecast = [] }

                if(burst.data.forecast.length) {
                    this.showForecast = true
                }
                if(burst.data.actual.length) {
                    this.showPerformance = true
                }
                
                return burst
            },

            currentMetricValue() {
                let activeRouteGraphSummaryData = this.activeRouteGraphSummaryData.find(x => x.name === this.localChartMetric)
                const currForecast = Number(activeRouteGraphSummaryData.forecast.replace(/[^\d\.\-]/g, ""))
                const currActual = Number(activeRouteGraphSummaryData.actual.replace(/[^\d\.\-]/g, ""))
                const currTarget = Number(activeRouteGraphSummaryData.target.replace(/[^\d\.\-]/g, ""))
                let performance = (currActual / currTarget) * 100
                let forecastPerformance = (currForecast / currTarget) * 100
                return {
                    forecast: currForecast,
                    actual: currActual,
                    target: currTarget,
                    performance: (isNaN(performance)) ? 0 : performance,
                    forecastPerformance: (isNaN(forecastPerformance)) ? 0 : forecastPerformance
                }
            },

            // PURPOSE: Style actual & forecast data on based on performance
            calculateChartColor(dataType) {
                // find the current metrics
                let currentMetrics = this.currentMetricValue()

                switch (dataType) {
                    case 'target':
                        return '#A020F0'

                    case 'forecast':
                        return (currentMetrics.forecast >= currentMetrics.target) ? '#28D791' : '#FD694E'
                        
                    case 'actual':
                        return (currentMetrics.actual >= currentMetrics.target) ? '#1D9E6A' : '#FC2803'
                }
            },

            // PURPOSE: Return an object with color, and icon for graph performance
            routeGraphPerformanceIcon(type = 'actual') {
                let currentMetrics = this.currentMetricValue()
                let val = currentMetrics.performance
                if(type === 'forecast') {
                    val = currentMetrics.forecastPerformance
                }
                if(val >= 100) {
                    return {
                        color: '#28D791',
                        icon: 'mdi-trending-up' 
                    }
                }

                else if(val < 100 && val !== 0) {
                    return { 
                        color: '#FD694E', 
                        icon: 'mdi-trending-down' 
                    }
                }

                return { 
                    color: (this.$vuetify.theme.dark) ? '#fff' : '#000',
                    icon: 'mdi-trending-neutral' 
                }
            },

            // PURPOSE: Calculate current performance metric percentage (performance / target * 100)
            currentPerformancePercent(type = 'actual') {
                
                // find the current metrics
                let currentMetrics = this.currentMetricValue()
                let result = currentMetrics.performance
                if(type === 'forecast') {
                    result = currentMetrics.forecastPerformance
                }

                return ((isNaN(result)) ? 0 : result.toFixed(2)) + '%'
            },

            getCampaignStatsTotals(data) 
            {
                let outObj = []
                let kpiListDescriptions = {'impacts': 'Unique viewers', 'plays': 'On Screen Plays', 'hours': 'Cumulative hours'}

                // loop through metrics
                this.kpiList.forEach(metric => {
                    // loop through the data and sum the values
                    // { name: 'Impacts/Impressions', target: 0, actual: 0, forecast: 0, performance: % }
                    let kpiShortname = this.getKpiShortNameFromName(metric)
                    let innerObj = {
                        name: metric,
                        shortName: kpiShortname,
                        description: kpiListDescriptions[kpiShortname],
                        target: Number(data.data.target.reduce((a, b) => a + b[kpiShortname], 0)),
                        actual: Number(data.data.actual.reduce((a, b) => a + b[kpiShortname], 0)),
                        forecast: Number(data.data.forecast.reduce((a, b) => a + b[kpiShortname], 0))
                    }
                    innerObj.performance = (innerObj.actual / innerObj.target) ? `${(innerObj.actual / innerObj.target * 100).toFixed(2)}%` : '0%' // calculate performance
                    innerObj.actual = this.addCommas(innerObj.actual)
                    innerObj.target = this.addCommas(innerObj.target)
                    innerObj.forecast = this.addCommas(innerObj.forecast)

                    outObj.push(innerObj)
                })

                return outObj
            },

            // PURPOSE: Get chart data based on selection of filters
            transformChartData() {
                if(!this.chartDataTransformed.bursts) return []

                // filter by auto/manual
                //let chartByDataType = this.splitChartByDesignation(this.data) // transform to required format

                const chartByDataType = cloneDeep(this.chartDataTransformed)
                // const chartByDataType = JSON.parse(JSON.stringify(this.chartData[this.localChartDataType])) // clone legacy

                // Filter by campaign
                let chartByCampaign = this.splitChartByCampaign([chartByDataType])

                // Filter by bursts needed (get an array)
                let chartByBurst = this.splitChartByBurst(chartByCampaign)

                // Filter by all or 1 burst
                this.chartDisplayData = chartByCampaign[0]
                if(this.localChartView !== 0){
                    this.chartDisplayData = chartByBurst[0]
                }

                // update the summary data (below the graph)
                this.activeRouteGraphSummaryData = this.getCampaignStatsTotals(this.chartDisplayData)
            },
        },
    }
</script>

<style scoped lang="scss">
    // Change border color of collected circle
    $progress-circular-underlay-stroke: rgba(map-get($grey, 'base'), 1) !default;
</style>

<style scoped>
    .grid-container {
        display: grid;
        text-align: left;
    }

    .spanRows {
        text-align: center;
    }
</style>